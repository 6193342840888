// add more home-pages from folder home-page and edit from .env.
import HomePage02 from "./home-page/home-page02";
import { client } from "../../lib/client";
import React from "react";

export default function Home({ homePage }) {
  const selectedPage = process.env.NEXT_PUBLIC_SELECTED_PAGE;

  switch (selectedPage) {
    case "1":
      return <HomePage02 pageData={homePage} />;
    case "2":
      return <HomePage02 pageData={homePage} />;
    case "3":
      return <HomePage02 pageData={homePage} />;
    default:
      return <HomePage02 pageData={homePage} />;
  }
}

export async function getStaticProps({}) {
  const query = `*[ _type == "page"][0]`;
  const homePage = await client.fetch(query);
  return {
    props: {
      homePage,
    },
    revalidate: 600, // 10m
  };
}
